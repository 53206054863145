.services-list {
	margin-left: 0;

	&.reach {
		.text .title {
			font-size: rem-calc(36);
			color: $red;
		}
	}

	li {
		list-style: none;
		margin-bottom: 3rem;
	}

	.image {
		width: 37%;
		float: left;
		margin-bottom: 2rem;

		@include breakpoint(medium down) {
			width: 100%;
			text-align: center;
		}

		a {
			display: block;
		}

		img {
			display: block;
			margin: 0 auto;

			@include breakpoint(small only) {
				margin: 0;
			}
		}
	}

	.text {
		float: left;
		width: 63%;
		padding-left: 1.5rem;

		@include breakpoint(medium down) {
			width: 100%;
			padding-left: 0;
		}

		.title {
			font-size: rem-calc(25);
			color: $slate;
		}

		p {
			font-size: rem-calc(15);
			color: $slate;
		}

		.learn-more {
			display: inline;
            text-decoration: none;
            color: #7a7a7a ;
            font-size: rem-calc(12);
            border-radius: .25rem;
            background-color: transparent;
            padding-top: .5625rem;
            padding-bottom: .5625rem;
            margin-top: 1rem;
            text-decoration: none;
            border: .125rem solid #7a7a7a ;
            text-transform: uppercase;
            font-weight: bold;
            padding-left: 1rem;
            padding-right: 1rem;
            transition: all 0.3s;

			.fa-caret-right {
				background-color: #7a7a7a;
				display: inline-block;
				vertical-align: middle;
				width: rem-calc(13);
				height: rem-calc(13);
				text-align: center;
				border-radius: 9999px;
				color: #fff;
				font-size: rem-calc(12);
				padding-left: rem-calc(1);
				padding-top: rem-calc(1);
			}
		}
	}
}
