.main-nav {
	clear: both;
	margin-left: 0;
	margin-bottom: 0;
	margin-top: rem-calc(12);

	@include breakpoint(medium down) {
		margin-top: 0;
		position: relative;
		top: 10px;
	}

	@include breakpoint(small only) {
		display: none;

		&.open {
			.dropdown {
				display: block;
				position: absolute;
				top: rem-calc(52);
				width: 100%;
			}

			.hamburger-menu {
				display: none;
			}

			.hamburger-close {
				display: inline-block;
			}
		}
	}

	ul {
		&.lvl-1 {
			margin-top: -12px;
			margin-left: rem-calc(-16);

			@include breakpoint(medium down) {
				margin-right: 0;
			}

			@include breakpoint(small only) {
				background-color: #EBEBEB;
				padding-bottom: 1rem;
				display: none;
			}

			li {
				list-style: none;
				display: inline-block;
				vertical-align: top;
				font-size: rem-calc(15);

				@include breakpoint(medium down) {
					font-size: rem-calc(15);
					margin-bottom: 0;
				}

				@include breakpoint(small only) {
					display: block;
					text-align: left;
				}

				&.is-active {
					background: #F9F9F9;

					&:after {
						content: '';
						position: absolute;
						z-index: 100;
						left: 0;
						bottom: -1px;
						width: 100%;
						height: rem-calc(1);
						background-color: #F9F9F9;
					}

					@include breakpoint(small only) {
						background-color: transparent;

						&:after {
							background-color: transparent;
						}
					}
				}
			}

			a {
				color: $red;
				transition: color 0.3s;
				padding: 1rem;

				@include breakpoint(medium down) {
					padding-bottom: rem-calc(6) !important;
					padding-left: 0.4rem !important;
					padding-right: 0.4rem !important;
				}

				@include breakpoint(small only) {
					font-size: rem-calc(27);
				}

				&:after {
					display: none !important;
				}

				&:hover {
					color: $slate;
				}
			}
		}

		&.lvl-2 {
			// display: none;
			text-align: left;
			width: rem-calc(290);
			background: #f9f9f9 !important;
			// padding: 1rem 0;
			border: none !important;
			box-shadow: rem-calc(2) rem-calc(2) rem-calc(2) 0 #a3aaae;

			@include breakpoint(small only) {
				width: 100%;
				min-width: auto;
				position: relative;
				top: 0 !important;
				right: 0 !important;
				left: 0 !important;
				border: none;
				background-color: #ebebeb;
				padding-bottom: 0;
			}

			li {
				display: inline-block;
				// width: rem-calc(175);
				text-align: left;
				border-bottom: rem-calc(1) solid $middle-gray;
				padding-left: 1rem;
				right: 1rem;
				transition: background 0.3s;
				margin-bottom: 0;
				padding-left: 0;

				&:first-child a {
					padding-left: 1rem;
				}

				&:last-child {
					border-bottom: none;
					margin-bottom: 0;
					padding-bottom: 0;
				}

				&:hover {
					background: #E1E1E1;

					a {
						color: $slate;
					}
				}

				@include breakpoint(small only) {
					display: block;
					width: 100%;
					margin-left: 1rem;
					margin-bottom: 1rem;

					img, p {
						display: none;
					}
				}
			}

			h5 {
				color: #ab0e1e;
				margin-bottom: 0;
			}

			img {
				margin-bottom: rem-calc(12);
			}

			p {
				font-size: rem-calc(11);
				font-weight: normal;
			}

			.link {
				margin-bottom: 0;
			}

			a {
				padding-bottom: 0.5rem;
				margin-bottom: 0.5rem;
				border: rem-calc(1) solid transparent;
				color: #464646;
				padding-left: 1rem !important;

				&:hover {
					// color: inherit;
				}

				@include breakpoint(small only) {
					color: #555;
					font-size: rem-calc(21);
				}
			}
		}
	}

	.top-nav-container {
		ul {
			margin-top: 1rem;
			margin-left: 0;
			text-align: left;
		}

		li {
			a {
				font-size: rem-calc(21);
				font-weight: normal;
			}

			&.social-link {
				display: inline-block;
				text-indent: -9999px;
				width: rem-calc(31);
				height: rem-calc(32);
				margin-left: rem-calc(8);
				margin-right: rem-calc(12);
			}

			&.facebook {
				background: url(../img/icon-facebook-mobile.png) 50% 50% no-repeat;
			}

			&.twitter {
				background: url(../img/icon-twitter-mobile.png) 50% 50% no-repeat;
			}

			&.linkedin {
				background: url(../img/icon-linkedin-mobile.png) 50% 50% no-repeat;
			}
		}
	}

	.secondary-nav {
		text-align: right;
		margin-bottom: 0;

		@include breakpoint(small only) {
			display: none;
		}

		li {
			list-style: none;
			display: inline-block;
			vertical-align: top;
			margin-bottom: 0;

			a {
				color: $gray;
				font-size: rem-calc(12);
				font-weight: bold;
				margin-left: 0.75rem;

				@include breakpoint(medium down) {
					font-size: rem-calc(9);
					margin-left: 0.5rem;
				}

				&:hover {
					color: $slate;
				}
			}
		}
	}
}
