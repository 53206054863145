.breadcrumbs-container-inner {
	@include clearfix();

	.breadcrumbs {
		margin-top: 0;
		position: static;

		@include breakpoint(medium down) {
			margin-top: 1rem;
			margin-left: rem-calc(6)
		}

		@include breakpoint(small only) {
			margin-top: 0;
			display: none;
		}
	}
}

.breadcrumbs {
	margin-bottom: 2rem;

	@include breakpoint(medium down) {
		margin-top: 0;
	}

	@include breakpoint(small only) {
		margin-top: 0;
		margin-left: 1rem;
		position: absolute;
		top: 0;
	}

	&.service-sub {
		margin-bottom: 0;
	}

	.crumb {
		font-size: rem-calc(12);
		display: inline-block;
		vertical-align: top;

		&:nth-child(n+2) {
			&:before {
				content: '|';
				color: #a3aaae;
				margin-left: rem-calc(8);
				margin-right: rem-calc(12);
			}
		}

		&.truncated {
			display: none;
		}

		a {
			color: #37424a;
			text-decoration: none;
		}
	}

	.current {
		a {
			color: $gray;
		}
	}
}
