.top-nav-bar {
	background: #E1E1E1;
	text-align: right;
	height: rem-calc(30);
	padding-top: rem-calc(6);

	@include breakpoint(small only) {

	}

	.no-padding {
		padding-left: 0;
	}

	.global-link {
		float: left;
		font-size: rem-calc(12);
		font-weight: bold;

		@include breakpoint(small only) {
			display: none;
		}

		a {
			color: $slate;
		}

		span {
			font-size: rem-calc(16);
			margin-right: rem-calc(2);
		}
	}

	.top-nav {
		float: right;
		margin-top: rem-calc(-6);
		height: rem-calc(30);

		@include breakpoint(small only) {
			float: none;
			width: 100%;
			text-align: left;
			position: relative;
			left: 0.75rem;
		}

		.location-label {
			color: $slate;
			font-size: rem-calc(12);
			font-weight: bold;
			display: inline-block;
			vertical-align: top;
			margin-top: rem-calc(2);

			@include breakpoint(small only) {
				font-size: rem-calc(9);
			}
		}

		.locations {
			display: inline-block;
			vertical-align: top;
			padding-top: rem-calc(7);

			.menu {
				@include shadow;
				min-width: 0;
				width: rem-calc(132);
				background: $light-gray;
				border: none;
				z-index: 999999;

				li {
					text-align: left;
					font-size: rem-calc(12);
					margin-bottom: 0;

					@include breakpoint(small only) {
						font-size: rem-calc(12);
					}

					&:first-child a {
						padding-top: 0.7rem;
					}

					a {
						color: $slate;
						font-weight: bold;
						padding-top: 0;

						&:hover {
							color: #a3aaae;
						}
					}
				}
			}
		}

		.selected {
			font-size: rem-calc(12);
			color: $slate;
			font-weight: bold;
			padding-top: 0;

			@include breakpoint(small only) {
				font-size: rem-calc(9);
			}

			&:after {
				display: none !important;
			}

			&:hover {
				color: #a3aaae;
			}

			.fa-caret-down {
				margin-left: rem-calc(4);
			}

			.inner {
				border-bottom: rem-calc(3) solid $red;
				padding-bottom: rem-calc(8);
			}
		}

		.locations {
			font-size: rem-calc(12);
		}

		a {
			color: $slate;
			transition: all 0.3s;

			&:hover {
				color: $middle-gray;
			}
		}
	}

	.language-nav {
		display: inline-block;
		vertical-align: top;
		margin-left: 0;
		margin-top: rem-calc(2);

		@include breakpoint(small only) {
			float: right;
			margin-right: 0.75rem;
			margin-bottom: 0;
		}

		li {
			display: inline-block;
			vertical-align: top;
			font-size: rem-calc(12);

			@include breakpoint(small only) {
				margin-bottom: rem-calc(8);
			}

			&:before {
				content: '|';
				margin-left: rem-calc(2);
				margin-right: rem-calc(6);
			}

			&:first-child:before {
				display: none;
			}
		}

		a {
			color: $slate;

			&:hover {
				color: $middle-gray;
			}

			&.selected {
				font-weight: bold;
				font-size: inherit;
			}
		}
	}
}
