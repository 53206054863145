.button.cta {
	margin-bottom: 0;
	font-size: rem-calc(15);
	padding: rem-calc(8) rem-calc(12);
	border-radius: rem-calc(4);
	text-decoration: none;
	transition: all 0.3s;

	&.red {
		color: $white;
		border: rem-calc(1) solid $red;
		background: linear-gradient(#8D0c18, #ab0e1e);

		&:hover {
			border: rem-calc(1) solid #a3aaae;
			background: linear-gradient($middle-gray, #6c6c6c);
		}
	}

	&.gray {
		font-size: rem-calc(15);
		border: rem-calc(1) solid #c8c8c8;
		background: linear-gradient($middle-gray, #A3AAAE);
		color: #464646;

		&:hover {
			background: linear-gradient(#243444, #000);
			color: $white;
		}
	}

	&.hollow-dark {
		background-color: transparent;
		color: #243444;
		border: rem-calc(1) solid #243444;

		&:hover {
			background: #243444;
			color: $white;
		}
	}

	&.hollow-light {
		color: $white;
		background-color: transparent;
		border: rem-calc(1) solid $white;

		&:hover {
			background-color: $white;
			color: #243444;
		}
	}
}
