.hamburger-menu {
	display: none;
	height: rem-calc(45);
	float: right;
	margin-top: rem-calc(16);
	position:relative;
	left: rem-calc(16);

	@include breakpoint(small only) {
		display: inline-block;
		margin-top: rem-calc(-4);
	}

	.menu-button {
		border-radius: rem-calc(3);
		height: 3px;
		width: 20px;
		position: relative;
		display: block;
		margin-top: rem-calc(15);
		margin-left: 1rem;
		margin-right: 1rem;
		color: $red;
		font-size: rem-calc(24);
		cursor: pointer;
	}

	.text {
		display: none;
	}
}

.hamburger-close {
	display: none;
	width: rem-calc(50);
	height: rem-calc(40);
	background-color: #1B2630;

	.close-button {
		height: rem-calc(40);
		width: rem-calc(20);
		margin-top: rem-calc(3);

		&:before, &:after {
			position: relative;
			display: block;
			content: '';
			width: rem-calc(22);
			height: rem-calc(6);
			background-color: $white;
			border-radius: rem-calc(2);
		}

		&:before {
			transform: rotate(-45deg);
			top: rem-calc(6);
		}

		&:after {
			transform: rotate(45deg)
		}
	}

	.text {
		display: none;
	}
}
