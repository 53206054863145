.sidebar-container {
	padding-left: 0;
	padding-right: 0;

	@include breakpoint(small only) {
		margin-bottom: 2.5rem;
	}
}

.sidebar {
	box-shadow: rem-calc(1) rem-calc(1) rem-calc(2) 0 #eaeaea;
	background: #f5f5f5;
	width: rem-calc(270);
	padding-top: rem-calc(8);
	padding-left: 1rem;
	padding-right: 1rem;

	@include breakpoint(medium only) {
		width: rem-calc(192);
	}

	@include breakpoint(small only) {
		margin-bottom: 2rem;
		width: 100%;
	}

	&.full {
		@include breakpoint(medium down) {
			width: 100%;
		}
	}

	ul {
		margin-bottom: 0;

		li {
			list-style: none;

			a {
				color: $slate;
				display: block;
				height: 100%;
				text-decoration: none;

				&:hover {
					color: $red;
				}
			}
		}

		&.lvl-1 {
			margin-left: 0;

			& > li {
				font-size: rem-calc(15);
				line-height: rem-calc(39);
				font-weight: bold;
				border-bottom: rem-calc(1) solid #ebebeb;
				padding-bottom: rem-calc(8);
				margin-bottom: rem-calc(8);

				&:last-child {
					border-bottom: none;
				}
			}

			& > li.current {
				a {
					color: $red;
				}
			}
		}

		&.lvl-2 {
			& > li {
				font-size: rem-calc(15);
				line-height: rem-calc(39);
				padding-bottom: rem-calc(2);
				margin-bottom: rem-calc(2);

				a {
					color: #555555;
				}
			}

			& > li[aria-expanded="true"] {
				a {
					color: $slate;
				}

				& > ul a {
					color: #555;
				}
			}

			& > li.current {
				a {
					color: $red;
				}
			}
		}

		&.lvl-3 {
			& > li {
				font-size: rem-calc(12);
				font-weight: normal;
				padding-bottom: rem-calc(2);
				margin-bottom: rem-calc(2);

				& > a {
					color: #555;
				}
			}

			& > li.current {
				a {
					color: $red !important;
				}
			}
		}
	}
}

.sidebar-block {
	margin-top: 2rem;
	padding: rem-calc(12);
	width: rem-calc(270);
	color: #fff;

	@include breakpoint(medium) {
		width: rem-calc(257);
	}

	@include breakpoint(small) {
		width: 90%;
		margin-left: auto;
		margin-right: auto;
	}

	&.connect {
		background: #8B0F1C;
	}

	&.contact {
		background: #253443;
		position: relative;

		.fa-caret-right {
			color: #253443;
		}

		.triangle {
			width: 0;
			height: 0;
			border-top: rem-calc(20) solid #253443;
			border-right: rem-calc(20) solid transparent;
			position: absolute;
			bottom: rem-calc(-14);
			left: 0;
		}
	}

	h4 {
		font-size: rem-calc(15);
	}

	.link {
		color: #fff;
		text-decoration: none;
		font-size: rem-calc(14);
	}

	.fa-caret-right {
		background-color: #fff;
		display: inline-block;
		vertical-align: middle;
		width: rem-calc(13);
		height: rem-calc(13);
		text-align: center;
		border-radius: 9999px;
		color: #8B0F1C;
		font-size: rem-calc(12);
		padding-left: rem-calc(1);
		padding-top: rem-calc(1);
		margin-right: rem-calc(3);
		position: relative;
		bottom: rem-calc(2);
	}
}


.right-rail {
	display: block;

	@include breakpoint(medium) {
		display: none;
	}

	@include breakpoint(large) {
		display: block;
	}

	&.mobile {
		display: none;
		margin-top: 3rem;

		@include breakpoint(medium) {
			display: block;
		}

		@include breakpoint(large) {
			display: none;
		}
	}

	h4 {
		text-align: left;
		color: $slate;
		text-transform: uppercase;
		font-size: rem-calc(15);
		font-weight: bold;
		font-family: $base-font-condensed;
	}

	.right-rail-container {
		background: #F5F5F5;
		box-shadow: rem-calc(2) rem-calc(2) rem-calc(1) rem-calc(0) $gray;
		margin-bottom: 2rem;

		@include breakpoint(medium down) {
			box-shadow: none;
		}
	}

	.people-container {
		border-bottom: rem-calc(3) solid #D0D3D4;
	}

	.people {
		margin: 0;
		padding: 1rem;
		padding-bottom: 0;

		li {
			list-style: none;
			font-size: 0;

			.image {
				display: inline-block;
				vertical-align: top;
				width: 33%;
			}

			.name {
				display: inline-block;
				vertical-align: top;
				width: 66%;
				font-size: rem-calc(15);
				color: #ab0e1e;
			}

			.location {
				font-size: rem-calc(12);
				color: #37424a;
			}
		}
	}

	.insights {
		padding: 1rem;
		margin: 0;

		li {
			list-style: none;
			border-bottom: rem-calc(1) solid $middle-gray;
			padding-bottom: rem-calc(8);

			&:last-child {
				border-bottom: none;
				padding-bottom: 0;
				margin-bottom: 0;
			}

			.type {
				font-size: rem-calc(15);
				color: $gray;
				font-weight: bold;
				font-variant: small-caps;
			}

			.fa-file-text {
				color: $slate;

				&.white {
					color: $gray;
				}
			}

			h5 {
				font-weight: normal;
				font-size: rem-calc(15);
				color: $slate;
				font-family: $base-font;
				text-transform: none;
				letter-spacing: rem-calc(0);
				line-height: 1.3;
			}
		}
	}

	.newsletter {
		img {
			width: 100%;
		}

		h4 {
			border-bottom: rem-calc(3) solid #A91122;
			margin-bottom: 0;
			padding-bottom: rem-calc(8);

			@include breakpoint(medium only) {
				padding-left: 1rem;
			}
		}

		h5 {
			padding: 1rem;
			text-transform: none;
			font-family: $base-font;
			color: #ab0e1e;
			font-size: rem-calc(15);
			font-weight: bold;
		}
	}

	.more {
		color: $gray;
		text-decoration: none;
		padding-left: 1rem;
		padding-bottom: 1rem;
		display: inline-block;

		.fa-caret-right {
			background-color: $gray;
			display: inline-block;
			vertical-align: middle;
			width: rem-calc(13);
			height: rem-calc(13);
			text-align: center;
			border-radius: 9999px;
			color: #fff;
			font-size: rem-calc(12);
			padding-left: rem-calc(2);
			padding-top: rem-calc(1);
			margin-right: rem-calc(3);
			position: relative;
			bottom: rem-calc(2);
		}
	}
}
