.featured-blocks {
	margin-bottom: 2rem;

	ul {
		margin-bottom: 0;

		@include breakpoint(medium down) {
			text-align: center;
		}
	}

	li {
		list-style: none;

		@include breakpoint(medium down) {
			background-color: $white;
			margin-bottom: 2rem;
		}
	}

	.slide {
		@include shadow;
		background: $white;
		border: rem-calc(1) solid #ddd;

		@include breakpoint(medium down) {
			box-shadow: none;
			border: none;
			margin: 0 auto;
			text-align: left;
		}

		@include breakpoint(medium only) {
			width: rem-calc(480);
		}

		&.red-top .subject {
			border-top: rem-calc(10) solid #a91122;
		}

		&.blue-top .subject {
			border-top: rem-calc(10) solid #243444;
		}

		&.gray-top .subject {
			border-top: rem-calc(10) solid #a3aaae;
		}
	}

	.subject, h3, p {
		padding-left: rem-calc(10);
	}

	.subject {
		color: #6c6c6c;
		font-size: rem-calc(15);
		padding-top: rem-calc(6);
		margin-bottom: rem-calc(6);
	}

	img {
		display: block;
		margin-bottom: rem-calc(10);

		@include breakpoint(medium down) {
			width: 100%;
		}
	}

	h3 {
		margin-bottom: 0;
		color: #243444;
	}

	p {
		color: #252525;
	}

	.cta {
		padding-bottom: rem-calc(8);

		a {
			color: #464646;
			text-decoration: none;
		}
	}
}
