.off-canvas-content {
	background: #fff !important;
}

.off-canvas {
	background: #F5F5F5;
	height: 100%;
}

.close-button {
	top: 3rem;
	color: $gray;

	&:hover {
		color: $red;
	}
}

.mobile-menu {
	.vertical.menu {
		margin-top: 3rem;
		margin-bottom: 1rem;

		li {
			border-bottom: rem-calc(1) solid #D0D3D4;
			margin-bottom: rem-calc(4);

			a {
				color: $red;
				font-weight: bold;
				font-size: rem-calc(15);
			}

			li {
				display: block;
				border-bottom: none;
				margin-left: 1rem;

				a {
					font-size: rem-calc(15);
					font-weight: normal;
				}
			}
		}
	}

	.secondary-nav {
		margin-left: 1rem;

		li {
			list-style: none;
			margin-bottom: rem-calc(6);

			a {
				color: $gray;
				font-size: rem-calc(15);
			}
		}
	}
}
