.page-terms {
	h1 {
		// text-align: center;
		color: $red;
	}

	p {
		// width: rem-calc(650);
		margin-bottom: 2rem;
		// margin-left: auto;
		// margin-right: auto;
		font-size: rem-calc(15);
		text-align: left;
	}
}
