.headline-scroller {
	h2 {
		border-bottom: rem-calc(2) solid #a3aaae;
		padding-bottom: 1.5rem;
		margin-bottom: 1.5rem;
		color: $slate;

		@include breakpoint(small only) {
			font-size: rem-calc(27);
			margin-bottom: 1rem;
			padding-bottom: 1rem;
		}
	}

	ul {
		margin-bottom: 2rem;
	}

	li {
		list-style: none;
	}

	p {
		color: #555555;
		font-size: rem-calc(15);
		margin-bottom: 3rem;

		@include breakpoint(medium down) {
			font-size: rem-calc(15);
		}

		@include breakpoint(small only) {
			margin-bottom: 1.688rem;
			font-size: rem-calc(12);
		}
	}

	.orbit-bullets {
		margin-top: rem-calc(-75);
		position: absolute;
		right: 48%;

		@include breakpoint(small only) {
			margin-top: rem-calc(-60);
			right: 40%;
		}

		button {
			width: rem-calc(12);
			height: rem-calc(12);
			border: rem-calc(2) solid $middle-gray;
			background-color: $middle-gray;
			margin-left: rem-calc(10);

			&.is-active {
				background-color: #a3aaae;
				border-color: #a3aaae;
			}
		}
	}
}
