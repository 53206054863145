#__bs_notify__ {
	display: none !important;
}

.main-bar {
	height: rem-calc(110);

	@include breakpoint(medium down) {
		height: rem-calc(90);
	}

	@include breakpoint(small only) {
		height: rem-calc(50);
	}

	&.gray {
		background: #d7dadb;
	}

	.logo {
		float: left;
		background: url(../img/logo-door.png) 0 rem-calc(27) no-repeat;
		background-size: rem-calc(285);
		width: rem-calc(600);
		height: rem-calc(110);
		line-height: 0;
		font-size: 0;

		@include breakpoint(medium down) {
			width: rem-calc(420);
			height: rem-calc(90);
			background-size: rem-calc(217);
		}

		@include breakpoint(small only) {
			width: rem-calc(160);
			height: rem-calc(50);
			background-position: 0 rem-calc(6);
			background-size: rem-calc(150);
			margin-bottom: 0;
		}

		a {
			display: block;
			height: 100%;
		}
	}

	.search {
		float: right;
		margin-top: rem-calc(45);

		@include breakpoint(small only) {
			clear: both;
			margin-top: rem-calc(12);
			float: left;
			width: 100%;
		}

		.q {
			width: rem-calc(260);
			color: $black;
			background: #fff url(../../assets/img/magnifying-glass.png) 95% 50% no-repeat;
			background-size: rem-calc(15);
			position: relative;
			z-index: 9999;

			@include breakpoint(medium down) {
				width: rem-calc(200);
				height: rem-calc(26);
				background-size: rem-calc(15);
				font-size: rem-calc(12);
			}

			@include breakpoint(small only) {
				width: 100%;
				margin-bottom: 0.5rem;
			}
		}
	}
}
