.inner .home-carousel ul {
	max-height: rem-calc(360) !important;
}

.home-carousel {
	margin-bottom: rem-calc(60);

	@include breakpoint(medium down) {
		margin-top: 1.5rem;
	}

	@include breakpoint(small) {
		margin-bottom: rem-calc(30);
	}

	&.inner {
		@include breakpoint(medium down) {
			display: none;
		}
	}

	ul {
		margin-left: 0;
		margin-bottom: 0;
		width: 100%;
		height: rem-calc(380) !important;
		margin: 0 auto;

		@include breakpoint(medium down) {
			width: 100%;
			height: auto !important;
		}
	}

	li {
		list-style: none;
		height: 100%;
		text-align: center;

		@include breakpoint(medium down) {
			width: 100%;
			height: auto;
		}
	}

	img {
		height: 100%;
		display: block;

		@include breakpoint(medium down) {
			min-width: 50%;
			height: rem-calc(250);
		}

		@include breakpoint(small only) {
			width: 100%;
			height: auto;
		}
	}

	.detail-text {
		position: absolute;
		top: 0;
		right: 0;
		background: $red;
		padding: 1.125rem;
		width: rem-calc(390);
		height: 100%;
		text-align: left;

		@include breakpoint(medium down) {
			width: 50%;
			height: rem-calc(250);
		}

		@include breakpoint(small only) {
			position: relative;
			width: 100%;
			right: 0;
			height: rem-calc(200);
		}

		h1 {
			font-weight: normal;
			color: $white;
			margin-top: 1rem;
			margin-bottom: 1rem;
			font-size: rem-calc(36);
			line-height: rem-calc(42);

			@include breakpoint(medium down) {
				font-size: rem-calc(27);
				margin-top: 0;
				margin-bottom: 1rem;
				line-height: rem-calc(32);
			}

			@include breakpoint(small only) {
				font-size: rem-calc(27);
			}
		}

		p {
			color: $white;
			font-size: rem-calc(15);
			line-height: rem-calc(27);
			margin-bottom: 1.5rem;

			@include breakpoint(medium down) {
				font-size: rem-calc(15);
				line-height: rem-calc(22);
			}

			@include breakpoint(small only) {
				margin-bottom: 0;
			}
		}

		.cta {
			@include breakpoint(small only) {
				display: none;
			}
		}
	}

	.orbit-bullets {
		margin-top: 1rem;
		height: 1rem;

		@include breakpoint(medium down) {
			margin-top: 0;
			margin-bottom: 0;
		}

		@include breakpoint(small only) {
			margin-top: 0;
			position: relative;
			display: none;
		}

		&.mobile {
			display: none;

			@include breakpoint(small only) {
				display: block;
				margin-top: rem-calc(-17);
				position: relative;
				bottom: rem-calc(16);
			}
		}

		button {
			width: rem-calc(12);
			height: rem-calc(12);
			border: rem-calc(2) solid $middle-gray;
			background-color: $middle-gray;
			margin-left: 0.6rem;
			margin-right: 0.6rem;

			@include breakpoint(small only) {
				width: rem-calc(8);
				height: rem-calc(8);
				margin-left: rem-calc(4);
				margin-right: rem-calc(4);
			}

			&.is-active {
				background-color: #A3AAAE;
				border-color: #A3AAAE;
			}
		}
	}
}
