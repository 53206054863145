.callout {
	text-align: center;
	background: url(../img/bg-callout.jpg) 50% 0 no-repeat;
	border: none;
	height: rem-calc(375);
	padding-top: rem-calc(68);
	margin-bottom: 0;

	@include breakpoint(small only) {
		background-image: url(../img/bg-callout-small.jpg);
		background-size: 100%;
		padding-top: rem-calc(32);
		height: rem-calc(300);
	}

	p {
		font-size: rem-calc(27);
		line-height: rem-calc(39);
		color: $white;
		max-width: rem-calc(750);
		margin: 0 auto 2rem auto;

		@include breakpoint(small only) {
			font-size: rem-calc(15);
			line-height: rem-calc(27);
		}
	}
}

.inner .callout {
	margin-bottom: 2rem;
}
