$base-font: $body-font-family;
$base-font-condensed: 'Roboto Condensed', sans-serif;

$red: #ab0e1e;
$dark-red: #8d0c18;
$slate: #243444;
$black: #252525;
$gray: #a3aaae;
$middle-gray: #D0D3D4;
$light-gray: #ebebeb;
