.services-detail {
	h2 {
		color: $red;
	}

	.intro {
		font-size: rem-calc(15);
		font-weight: bold;
		margin-bottom: 2rem;
	}

	p {
		font-size: rem-calc(15);
		color: $slate;
		margin-bottom: 2rem;
	}
}
