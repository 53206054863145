.hero {
	position: relative;
	width: 100%;
	height: rem-calc(325);
	background: url('../../assets/img/placeholders/slider-inner.jpg') 50% 50% no-repeat;
	margin-bottom: 4rem;

	@include breakpoint(medium down) {
		top: rem-calc(24);
	}

	@include breakpoint(small only) {
		top: 4rem;
	}

	&.services {
		background-image: url('../../assets/img/placeholders/slider-inner-2.jpg');
	}

	&.reach {
		background-image: url('../../assets/img/placeholders/slider-inner-reach.jpg');
	}

	.inner {
		@include breakpoint(medium down) {
			padding-left: 1rem !important;
			padding-right: 1rem !important;
		}
	}

	.top {
		margin-top: 1rem;

		@include breakpoint(small only) {
			margin-top: 0;
		}
	}

	.breadcrumbs {
		float: left;
		width: 75%;


		@include breakpoint(small only) {
			display: none;
		}

		.crumb {
			a {
				color: #fff;
			}

			&:nth-child(n+2) {
				&:before {
					color: #fff;
				}
			}
		}
	}

	.social-hero {
		float: right;
		width: 25%;
		margin-top: 0;
	}

	.text {
		position: absolute;
		top: rem-calc(100);
	}

	h1 {
		color: #fff;

		&.small-mobile {
			@include breakpoint(small only) {
				font-size: rem-calc(38);
			}
		}
	}

	p {
		color: #fff;
		width: rem-calc(600);
		font-size: rem-calc(21);

		@include breakpoint(small only) {
			width: 100%;
			padding-right: 1rem;
		}

		&.small-mobile {
			@include breakpoint(small only) {
				font-size: rem-calc(16);
			}
		}
	}
}

.hero-image {
	margin-bottom: 2rem;

	@include breakpoint(small only) {
		margin-bottom: 0;
	}
}
