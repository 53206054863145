.primary-intro {

}

.secondary-intro {
	margin-bottom: 3rem;
}

.news-items {
	@include breakpoint(medium down) {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	@include breakpoint(small only) {
		width: 100%;
	}

	ul {
		margin-left: 0;
	}

	.row {
		list-style: none;
		margin-bottom: 3rem;

		img {
			@include breakpoint(medium down) {
				margin-bottom: 1rem;
			}

			@include breakpoint(small only) {
				margin-bottom: rem-calc(4);
			}
		}
	}

	.body {
		@include breakpoint(small only) {
			padding-left: 1rem !important;
			padding-right: 1rem !important;
		}
	}

	h2 {
		color: $red;

		@include breakpoint(small only) {
			font-size: rem-calc(27);
		}
	}

	p {
		font-size: rem-calc(21);
		line-height: rem-calc(33);
		color: #555;

		@include breakpoint(small only) {
			font-size: rem-calc(15);
			line-height: rem-calc(27);
		}
	}
}

.top-story {
	margin-bottom: 0rem;

	.intro {
		color: $slate;
		font-size: rem-calc(21);
		line-height: rem-calc(33);
	}
}

.news-items-horizontal {
	margin-bottom: 3rem;

	@include breakpoint(medium down) {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	@include breakpoint(small only) {
		width: 100%;
		margin-bottom: 2rem;
	}

	&.round {
		margin-bottom: 3rem;

		img {
			border-radius: 9999px;
		}

		h3 {
			color: $slate;
		}
	}

	ul {
		margin-bottom: 0;
	}

	li {
		list-style: none;

		@include breakpoint(medium down) {
			margin-bottom: 2rem;
		}
	}

	img {
		display: block;
		margin-bottom: 1.5rem;

		@include breakpoint(medium down) {
			width: 100%;
		}

		@include breakpoint(small only) {
			margin-bottom: rem-calc(4);
		}
	}

	h3 {
		color: $red;
		margin-bottom: 0;
	}

	h5 {
		color: $slate;
	}

	p {
		@include breakpoint(small only) {
			font-size: rem-calc(15);
		}
	}

	.benefits {
		margin-left: 0;

		li {
			color: #555;
		}
	}
}

.news-item-single {
	margin-bottom: 3rem;

	.content {
		@include shadow;
		background: #F5F5F5;
		padding: rem-calc(10);

		@include breakpoint(small only) {
			margin-bottom: 1rem;
		}

		li {
			list-style: disc;
		}
	}

	h3 {
		color: $slate;
	}
}
