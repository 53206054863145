.footer {
	.footer-columns {
		padding-top: 2rem;
		padding-bottom: 2rem;
		background-color: #f5f5f5;

		h5 {
			color: #555;
			font-size: rem-calc(15);
			line-height: rem-calc(39);
			font-weight: 700;
			color: $red;
			text-transform: none;
		}

		ul {
			margin-left: 0;

			@include breakpoint(small only) {
				margin-bottom: 2rem;
			}
		}

		li {
			list-style: none;
			margin-bottom: 1.5rem;

			@include breakpoint(small only) {
				margin-bottom: 0.5rem;
			}

			a {
				color: $slate;
				transition: all 0.3s;

				&:hover {
					color: $black;
				}
			}
		}
	}

	.footer {
		margin-top: rem-calc((-8));

		@include breakpoint(small only) {
			display: block;
		}

		.share-label {
			display: none;
		}

		li {
			display: block;
			text-align: left;
			margin-bottom: 0.5rem;
			width: auto;
		}

		.icon {
			color: $red;
			font-size: rem-calc(36);
			position: relative;
			top: rem-calc(7);
			margin-right: rem-calc(4);
		}

		a {
			font-size: rem-calc(15);
			color: $slate;
		}
	}

	.site-copyright {
		font-size: rem-calc(9);
		line-height: rem-calc(15);
		color: $slate;
		padding-top: 1rem;
		clear: both;
		text-align: center;
		margin-bottom: 0;
		font-weight: 400;
		color: #5f6772;

		@include breakpoint(medium down) {
			text-align: center;
		}

		@include breakpoint(small only) {
			font-size: rem-calc(7);
		}

		a {
			color: inherit;
			text-decoration: underline;
		}
	}

	.footer-nav {
		float: right;
		padding-top: 1rem;

		@include breakpoint(small only) {
			float: left;
		}

		ul {
			margin-left: 0;
			margin-bottom: 0;
		}

		li {
			display: inline-block;
			font-size: rem-calc(12);
			margin-left: 1rem;

			@include breakpoint(small only) {
				margin-left: 0;
				margin-right: 1rem;
			}
		}

		a {
			color: #a3aaae;
		}
	}
}
