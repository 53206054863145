.page-intro {
	h2 {
		text-align: center;
		font-size: rem-calc(24);
		width: rem-calc(800);
		margin: 0 auto 3rem auto;
		line-height: 1.3;

		@include breakpoint(medium down) {
			width: 100%;
			margin-top: 2rem;
		}
	}
}
